import React from 'react'

const SvgFilemanageBanner = props => (
  <svg
    id='filemanage_banner_svg__exchangeplatform'
    x={0}
    y={0}
    viewBox='0 0 1259.6 736.8'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.filemanage_banner_svg__st1{enable-background:new}.filemanage_banner_svg__st28{fill:#dddcf9}.filemanage_banner_svg__st33{fill:#fff}.filemanage_banner_svg__st71,.filemanage_banner_svg__st72{fill:none;stroke:#fff;stroke-miterlimit:10}.filemanage_banner_svg__st72{stroke-dasharray:6.3388,6.3388}.filemanage_banner_svg__st74{fill:#1e49e2}'
      }
    </style>
    <linearGradient
      id='filemanage_banner_svg__SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={-0.025}
      y1={186.4}
      x2={1259.6}
      y2={186.4}
      gradientTransform='translate(0 182)'
    >
      <stop offset={0} stopColor='#5c90fe' stopOpacity={0.1} />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.071} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.039} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M1259.6 207.7v529.1H370.8L25.6 537.6c-34-19.6-34.2-51.4-.4-71L802.7 14.7c33.8-19.6 88.7-19.6 122.6 0l334.3 193z'
      fill='url(#filemanage_banner_svg__SVGID_1_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_2_'
        gradientUnits='userSpaceOnUse'
        x1={1078.4}
        y1={15.65}
        x2={1135.8}
        y2={15.65}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_2_)' d='M1135.8 197.6l-28.7 16.7-28.7-16.7L1107 181z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_3_'
        gradientUnits='userSpaceOnUse'
        x1={1121.25}
        y1={206.4}
        x2={1121.25}
        y2={15.6}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_3_)' d='M1135.8 197.6l-.5 174.2-28.6 16.6.4-174.1z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_4_'
          gradientUnits='userSpaceOnUse'
          x1={1092.5}
          y1={206.4}
          x2={1092.5}
          y2={15.6}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_4_)' d='M1107.1 214.3l-.4 174.1-28.8-16.6.5-174.2z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_5_'
        gradientUnits='userSpaceOnUse'
        x1={403}
        y1={119.25}
        x2={460}
        y2={119.25}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_5_)' d='M460 301.2l-28.4 16.6-28.6-16.6 28.4-16.5z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_6_'
        gradientUnits='userSpaceOnUse'
        x1={445.55}
        y1={308.8}
        x2={445.55}
        y2={119.2}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_6_)' d='M460 301.2l-.5 173.1-28.4 16.5.5-173z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_7_'
          gradientUnits='userSpaceOnUse'
          x1={417.05}
          y1={308.8}
          x2={417.05}
          y2={119.2}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_7_)' d='M431.6 317.8l-.5 173-28.6-16.5.5-173.1z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_8_'
        gradientUnits='userSpaceOnUse'
        x1={686.5}
        y1={407.85}
        x2={737.563}
        y2={407.85}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_8_)' d='M737.5 589.8l-25.4 10.9-25.6-10.9 25.4-10.8z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_9_'
        gradientUnits='userSpaceOnUse'
        x1={724.55}
        y1={532.2}
        x2={724.55}
        y2={407.8}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_9_)' d='M737.5 589.8l-.4 113.6-25.5 10.8.5-113.5z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_10_'
          gradientUnits='userSpaceOnUse'
          x1={699.05}
          y1={532.2}
          x2={699.05}
          y2={407.8}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_10_)' d='M712.1 600.7l-.5 113.5-25.6-10.8.5-113.6z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_11_'
        gradientUnits='userSpaceOnUse'
        x1={557.6}
        y1={342.3}
        x2={601.5}
        y2={342.3}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_11_)' d='M601.5 524.3L579.6 537l-22-12.7 21.9-12.7z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_12_'
        gradientUnits='userSpaceOnUse'
        x1={590.35}
        y1={488.2}
        x2={590.35}
        y2={342.3}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_12_)' d='M601.5 524.3l-.4 133.2-21.9 12.7.4-133.2z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_13_'
          gradientUnits='userSpaceOnUse'
          x1={568.4}
          y1={488.2}
          x2={568.4}
          y2={342.3}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_13_)' d='M579.6 537l-.4 133.2-22-12.7.4-133.2z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_14_'
        gradientUnits='userSpaceOnUse'
        x1={325.8}
        y1={252.6}
        x2={374.4}
        y2={252.6}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_14_)' d='M374.4 434.6l-24.2 14.1-24.4-14.1 24.2-14.1z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_15_'
        gradientUnits='userSpaceOnUse'
        x1={362.1}
        y1={414.2}
        x2={362.1}
        y2={252.6}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_15_)' d='M374.4 434.6l-.4 147.5-24.2 14.1.4-147.5z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_16_'
          gradientUnits='userSpaceOnUse'
          x1={337.8}
          y1={414.2}
          x2={337.8}
          y2={252.6}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_16_)' d='M350.2 448.7l-.4 147.5-24.4-14.1.4-147.5z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_17_'
        gradientUnits='userSpaceOnUse'
        x1={1157.5}
        y1={205.8}
        x2={1201.4}
        y2={205.8}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_17_)' d='M1201.4 387.8l-21.8 12.7-22.1-12.7 21.9-12.7z' />
      <linearGradient
        id='filemanage_banner_svg__SVGID_18_'
        gradientUnits='userSpaceOnUse'
        x1={1190.3}
        y1={351.7}
        x2={1190.3}
        y2={205.8}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
        <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path fill='url(#filemanage_banner_svg__SVGID_18_)' d='M1201.4 387.8l-.3 133.2-21.9 12.7.4-133.2z' />
      <g>
        <linearGradient
          id='filemanage_banner_svg__SVGID_19_'
          gradientUnits='userSpaceOnUse'
          x1={1168.4}
          y1={351.7}
          x2={1168.4}
          y2={205.8}
          gradientTransform='translate(0 182)'
        >
          <stop offset={0} stopColor='#f0eeff' stopOpacity={0} />
          <stop offset={0.5} stopColor='#e9e8fd' stopOpacity={0.5} />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path fill='url(#filemanage_banner_svg__SVGID_19_)' d='M1179.6 400.5l-.4 133.2-22-12.7.3-133.2z' />
      </g>
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_20_'
        gradientUnits='userSpaceOnUse'
        x1={514.155}
        y1={122.953}
        x2={625.38}
        y2={122.953}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M623.5 306.6c4 2.3 4 6 .1 8.3l-45.3 26.5c-3.9 2.3-10.3 2.3-14.3 0l-45.7-26.2c-4-2.3-4-6-.1-8.3l45.3-26.5c3.9-2.3 10.3-2.3 14.3 0l45.7 26.2z'
        fill='url(#filemanage_banner_svg__SVGID_20_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_21_'
        gradientUnits='userSpaceOnUse'
        x1={625.339}
        y1={148.382}
        x2={514.043}
        y2={148.382}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M626.4 311.1v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3 0 .1-.1.2-.1.2 0 .1-.1.2-.1.3 0 .1-.1.2-.1.2-.1.1-.1.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-45.3 26.5c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.1.2h-3.6c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.4-.7L518.1 315c-2-1.1-3-2.7-3-4.2v19.3c0 1.5 1 3 3 4.2l45.7 26.2c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3 1-.5l45.3-26.5s.1 0 .1-.1c.1-.1.2-.1.3-.2s.2-.2.4-.2c.1-.1.2-.1.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4l.1-.1v-.1c.1-.1.1-.2.1-.3 0-.1.1-.1.1-.2v-.1c0-.1.1-.2.1-.3 0-.1 0-.1.1-.2v-.1-.3-19.6c-.1.5-.1.6-.2.7z'
        fill='url(#filemanage_banner_svg__SVGID_21_)'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_22_'
      gradientUnits='userSpaceOnUse'
      x1={606.818}
      y1={122.951}
      x2={531.535}
      y2={122.951}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#dddcf9' />
      <stop offset={0.562} stopColor='#fff' />
      <stop offset={0.708} stopColor='#f9f8fe' />
      <stop offset={0.915} stopColor='#e6e6fb' />
      <stop offset={1} stopColor='#dddcf9' />
    </linearGradient>
    <path
      d='M570.5 332.7c-1.1 0-1.8-.2-2-.3l-35.2-20.2c-1-.6-1-1.9 0-2.5l34.8-20.3c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.2 20.2c1 .6 1 1.9 0 2.5l-34.8 20.3c-.1.1-.8.3-1.9.3z'
      fill='url(#filemanage_banner_svg__SVGID_22_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_23_'
        gradientUnits='userSpaceOnUse'
        x1={514.152}
        y1={87.853}
        x2={625.377}
        y2={87.853}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M623.4 271.5c4 2.3 4 6 .1 8.3l-45.3 26.5c-3.9 2.3-10.3 2.3-14.3 0l-45.7-26.2c-4-2.3-4-6-.1-8.3l45.3-26.5c3.9-2.3 10.3-2.3 14.3 0l45.7 26.2z'
        fill='url(#filemanage_banner_svg__SVGID_23_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_24_'
        gradientUnits='userSpaceOnUse'
        x1={625.339}
        y1={113.182}
        x2={514.043}
        y2={113.182}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M626.4 275.9v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3 0 .1-.1.2-.1.2 0 .1-.1.2-.1.3 0 .1-.1.2-.1.2-.1.1-.1.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-45.3 26.5c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.1.2h-3.6c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.4-.7l-45.7-26.2c-2-1.1-3-2.7-3-4.2v19.3c0 1.5 1 3 3 4.2l45.7 26.2c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3 1-.5l45.3-26.5s.1 0 .1-.1c.1-.1.2-.1.3-.2s.2-.2.4-.2c.1-.1.2-.1.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4l.1-.1v-.1c.1-.1.1-.2.1-.3 0-.1.1-.1.1-.2v-.1c0-.1.1-.2.1-.3 0-.1 0-.1.1-.2v-.1-.3-19.6c-.2.5-.2.6-.2.7z'
        fill='url(#filemanage_banner_svg__SVGID_24_)'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={606.814}
      y1={87.851}
      x2={531.531}
      y2={87.851}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#dddcf9' />
      <stop offset={0.562} stopColor='#fff' />
      <stop offset={0.708} stopColor='#f9f8fe' />
      <stop offset={0.915} stopColor='#e6e6fb' />
      <stop offset={1} stopColor='#dddcf9' />
    </linearGradient>
    <path
      d='M570.4 297.6c-1.1 0-1.8-.2-2-.3l-35.2-20.2c-1-.6-1-1.9 0-2.5l34.8-20.3c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.2 20.2c1 .6 1 1.9 0 2.5l-34.8 20.3c-.1 0-.8.3-1.9.3z'
      fill='url(#filemanage_banner_svg__SVGID_25_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_26_'
        gradientUnits='userSpaceOnUse'
        x1={509.845}
        y1={53.941}
        x2={621.07}
        y2={53.941}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M619 237.6c4 2.3 4 6 .1 8.3l-45.3 26.5c-3.9 2.3-10.3 2.3-14.3 0l-45.7-26.2c-4-2.3-4-6-.1-8.3l45.3-26.5c3.9-2.3 10.3-2.3 14.3 0l45.7 26.2z'
        fill='url(#filemanage_banner_svg__SVGID_26_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_27_'
        gradientUnits='userSpaceOnUse'
        x1={621.059}
        y1={79.37}
        x2={509.763}
        y2={79.37}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M622 242.1v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3s-.1.2-.1.2c0 .1-.1.2-.1.3s-.1.2-.1.2c-.1.1-.1.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-45.3 26.5c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.1.2h-3.6c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.4-.7L513.7 246c-2-1.1-3-2.7-3-4.2v19.3c0 1.5 1 3 3 4.2l45.7 26.2c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3 1-.5l45.3-26.5s.1 0 .1-.1c.1-.1.2-.1.3-.2s.2-.2.4-.2c.1-.1.2-.1.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4l.1-.1v-.1c.1-.1.1-.2.1-.3 0-.1.1-.1.1-.2v-.1c0-.1.1-.2.1-.3 0-.1 0-.1.1-.2v-.1-.3-19.6c-.2.5-.2.6-.2.7z'
        fill='url(#filemanage_banner_svg__SVGID_27_)'
      />
    </g>
    <path
      className='filemanage_banner_svg__st28'
      d='M566.1 263.7c-1.1 0-1.8-.2-2-.3l-35.2-20.2c-1-.6-1-1.9 0-2.5l34.8-20.3c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.2 20.2c1 .6 1 1.9 0 2.5L568 263.4c-.2 0-.9.3-1.9.3z'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={569.108}
      y1={66.951}
      x2={569.108}
      y2={-53.225}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M542.3 134.8l.4 104.3c0 .4.2.7.5.9l25.6 14.7c.1.1.6.2 1.4.2.8 0 1.3-.2 1.4-.2L597 240c.4-.2.5-.6.5-1l-.4-104.3-54.8.1z'
      fill='url(#filemanage_banner_svg__SVGID_28_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={569.135}
      y1={74.451}
      x2={569.135}
      y2={-38.252}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M532.2 149.8l.2 90.9c0 .5.2 1 .7 1.2l35.2 20.2c.2.1.9.3 2 .3s1.8-.2 1.9-.3l34.8-20.3c.5-.3.7-.8.7-1.3l-.2-90.9-75.3.2z'
      fill='url(#filemanage_banner_svg__SVGID_29_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={560.108}
      y1={-20.105}
      x2={596.366}
      y2={42.696}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#7f64ff' />
      <stop offset={1} stopColor='#6b49ff' />
    </linearGradient>
    <path
      d='M599.9 215.7c0-.4 0-.8-.1-1.2 0-.4-.1-.8-.1-1.2s-.1-.8-.1-1.3c-.1-.4-.1-.9-.2-1.3-.1-.3-.1-.7-.2-1 0-.1 0-.2-.1-.3-.1-.5-.2-.9-.3-1.4s-.2-.9-.3-1.4-.2-.9-.4-1.4c-.1-.5-.3-.9-.4-1.4-.1-.4-.3-.9-.4-1.3-.1-.4-.3-.9-.5-1.3s-.3-.8-.5-1.3c-.2-.4-.3-.8-.5-1.2s-.3-.8-.5-1.2l-.6-1.2c-.2-.4-.4-.9-.7-1.3-.3-.5-.5-1-.8-1.5-.4-.8-.9-1.5-1.3-2.3-.7-1.1-1.4-2.3-2.1-3.3l-1.5-2.1c-.4-.5-.7-.9-1.1-1.4-.3-.4-.7-.8-1-1.2s-.7-.8-1-1.2-.7-.8-1-1.1l-1.1-1.1-1.1-1.1c-.4-.4-.8-.7-1.1-1.1-.4-.3-.8-.7-1.2-1s-.8-.6-1.1-.9c-.4-.3-.8-.6-1.1-.9-.4-.3-.7-.5-1.1-.8-.4-.2-.7-.5-1.1-.7-.4-.2-.7-.4-1.1-.6-.1 0-.2-.1-.2-.1-.3-.2-.5-.3-.8-.4-.3-.2-.7-.4-1-.5-.3-.2-.7-.3-1-.5s-.7-.3-1-.4c-.3-.1-.7-.3-1-.4-.3-.1-.7-.2-1-.3h-.1c-.4-.1-.7-.2-1.1-.3-.4-.1-.7-.2-1.1-.2-.4-.1-.8-.1-1.1-.2-.4-.1-.8-.1-1.2-.1h-1.2c-.5 0-.9 0-1.3.1-.5 0-1 .1-1.5.2-.6.1-1.2.3-1.8.5-.7.3-1.4.6-2.1 1l-7.8 4.6c.6-.4 1.3-.7 2.1-1 .6-.2 1.2-.4 1.8-.5.5-.1 1-.2 1.5-.2.4 0 .9-.1 1.3-.1h1.2c.4 0 .8.1 1.2.1s.7.1 1.1.2c.4.1.7.1 1.1.2.4.1.7.2 1.1.3h.1c.1 0 .2.1.3.1.4.2 1.4.5 1.7.6s.7.3 1 .4c.3.2.7.3 1 .5s.7.3 1 .5c.3.1.5.3.8.4.1 0 .2.1.2.1.4.2.7.4 1.1.6.4.2.7.5 1.1.7.4.2.7.5 1.1.8.4.3.8.6 1.1.9.4.3.8.6 1.1.9.4.3.8.7 1.2 1s.8.7 1.1 1.1l1.1 1.1 1.1 1.1c.3.4.7.7 1 1.1.3.4.7.8 1 1.2s.7.8 1 1.2c.4.5.7.9 1.1 1.4l1.5 2.1c.7 1.1 1.5 2.2 2.1 3.3.4.8.9 1.5 1.3 2.3.3.5.5 1 .8 1.5.2.4.4.9.7 1.3l.6 1.2c.2.4.4.8.5 1.2.2.4.3.8.5 1.2s.3.8.5 1.3c.2.4.3.9.5 1.3s.3.9.4 1.3c.1.5.3.9.4 1.4.1.5.3.9.4 1.4.1.5.2.9.3 1.4s.2.9.3 1.4c0 .1 0 .2.1.3.1.3.1.7.2 1 .1.4.1.9.2 1.3s.1.9.1 1.3.1.8.1 1.2 0 .8.1 1.2v2.4c0 .4 0 .8-.1 1.2 0 .4-.1.8-.1 1.2-.1.4-.1.8-.2 1.2-.1.4-.2.9-.3 1.3-.1.4-.2.9-.3 1.3-.1.5-.3.9-.4 1.4l-.6 1.5c-.3.6-.6 1.2-1 1.8-1.2 1.9-2.7 3.3-4.4 4.4l7.8-4.6c1.8-1 3.3-2.5 4.4-4.4.4-.6.7-1.2 1-1.8l.6-1.5c.2-.4.3-.9.4-1.4.1-.4.2-.9.3-1.3.1-.4.2-.8.3-1.3.1-.4.1-.8.2-1.2 0-.4.1-.8.1-1.2s.1-.8.1-1.2v-1.2-1.2z'
      fill='url(#filemanage_banner_svg__SVGID_30_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_31_'
      gradientUnits='userSpaceOnUse'
      x1={539.717}
      y1={18.709}
      x2={591.141}
      y2={18.709}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#7f64ff' />
      <stop offset={1} stopColor='#6b49ff' />
    </linearGradient>
    <path
      d='M566.3 177.1c11.7 6.7 22.2 21.9 25 37.1 3.3 17.8-5.3 28.8-19.1 24.6-2-.6-4-1.5-5.9-2.6-11.7-6.7-22.2-21.9-25-37.1-3.3-17.7 5.3-28.7 19.1-24.6 2 .6 3.9 1.5 5.9 2.6z'
      fill='url(#filemanage_banner_svg__SVGID_31_)'
    />
    <path
      className='filemanage_banner_svg__st33'
      d='M566.4 181.8c9.9 5.7 18.8 18.5 21.2 31.4 2.8 15-4.5 24.3-16.2 20.8-1.7-.5-3.4-1.3-5-2.2-9.9-5.7-18.8-18.5-21.2-31.4-2.8-15 4.5-24.3 16.2-20.8 1.7.5 3.3 1.2 5 2.2z'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={544.938}
      y1={18.791}
      x2={586.123}
      y2={18.791}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#7f64ff' />
      <stop offset={1} stopColor='#6b49ff' />
    </linearGradient>
    <path
      d='M566.4 183.1c9.3 5.4 17.8 17.6 20 29.7 2.6 14.2-4.3 23-15.3 19.7-1.6-.5-3.2-1.2-4.8-2.1-9.3-5.4-17.8-17.6-20-29.7-2.6-14.2 4.3-23 15.3-19.7 1.6.5 3.2 1.2 4.8 2.1z'
      fill='url(#filemanage_banner_svg__SVGID_32_)'
    />
    <path
      className='filemanage_banner_svg__st33'
      d='M579.6 203.7c-4.7-2.7-9.3-7-13.2-12.3h-.1c-3.9.9-8.5-.2-13.2-2.9l-.2-.1.3 3.4c.9 12 5.6 21.8 13.1 33.4l.2.1c7.5-2.9 12.2-7.4 13.1-18.3l.3-3.1-.3-.2zm-6.5 3.6c-.5 5.6-2.8 7.9-6.7 9.4l-.1-.1c-3.8-6-6.2-11-6.7-17.1l-.1-1.7.1.1c2.4 1.4 4.8 1.9 6.8 1.5 2 2.7 4.3 4.9 6.8 6.3l.1.1-.2 1.5z'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={1668.472}
      y1={235.7}
      x2={2043.747}
      y2={235.7}
      gradientTransform='matrix(-1 0 0 1 2695.26 182)'
    >
      <stop offset={0} stopColor='#c3e1fb' />
      <stop offset={0.465} stopColor='#c1e0fb' />
      <stop offset={0.632} stopColor='#badffb' />
      <stop offset={0.751} stopColor='#afdbfb' />
      <stop offset={0.848} stopColor='#9ed7fa' />
      <stop offset={0.929} stopColor='#88d1fa' />
      <stop offset={1} stopColor='#6ecaf9' />
    </linearGradient>
    <path
      d='M664.4 399.9c-17.1 9.9-17.2 25.9-.2 35.7L807.7 519c17 9.9 44.6 9.9 61.7 0l144.5-83.4c17.1-9.9 17.2-25.9.2-35.8l-143.5-83.4c-17-9.9-44.6-9.9-61.7 0l-144.5 83.5z'
      fill='url(#filemanage_banner_svg__SVGID_33_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_34_'
      gradientUnits='userSpaceOnUse'
      x1={1854.873}
      y1={342.175}
      x2={1854.873}
      y2={-174.575}
      gradientTransform='matrix(-1 0 0 1 2695.26 182)'
    >
      <stop offset={0} stopColor='#334bf2' />
      <stop offset={0.04} stopColor='#3d54f3' stopOpacity={0.96} />
      <stop offset={0.391} stopColor='#8f9cf8' stopOpacity={0.609} />
      <stop offset={0.679} stopColor='#ccd2fc' stopOpacity={0.321} />
      <stop offset={0.889} stopColor='#f1f2fe' stopOpacity={0.111} />
      <stop offset={1} stopColor='#fff' stopOpacity={0} />
    </linearGradient>
    <path
      d='M652.8 416.7V115.2c-.4-6.7 3.9-13.4 12.8-18.5l144.5-82c17.1-9.7 44.7-9.7 61.7 0l143.5 82c5.6 3.2 9.3 7.1 11.2 11.2h1.5V114.3l-.3 300.2c.4 1.8.4 3.7 0 5.5v.1c-1.1 5.4-5.3 10.7-12.6 14.8l-144.5 82c-17.1 9.7-44.7 9.7-61.7 0l-143.5-82c-8.8-5-13-11.7-12.6-18.2z'
      opacity={0.39}
      fill='url(#filemanage_banner_svg__SVGID_34_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={1571.36}
      y1={271.813}
      x2={2148.06}
      y2={271.813}
      gradientTransform='matrix(-1 0 0 1 2695.26 182)'
    >
      <stop offset={0} stopColor='#00dcf3' stopOpacity={0} />
      <stop offset={0.13} stopColor='#08c2f2' stopOpacity={0.104} />
      <stop offset={0.423} stopColor='#188bef' stopOpacity={0.338} />
      <stop offset={0.676} stopColor='#2463ed' stopOpacity={0.541} />
      <stop offset={0.877} stopColor='#2b4beb' stopOpacity={0.702} />
      <stop offset={1} stopColor='#2e42eb' stopOpacity={0.8} />
    </linearGradient>
    <path
      d='M813.4 616.9L556.8 467.8c-6.2-3.6-9.6-8.6-9.6-14s3.5-10.4 9.7-14l258.3-149.1c12-6.9 30.6-6.9 42.5 0l256.6 149.1c6.2 3.6 9.6 8.5 9.6 13.9s-3.5 10.4-9.7 14L856 616.9c-6 3.5-13.6 5.2-21.3 5.2-7.7 0-15.3-1.7-21.3-5.2zm4.8-321.2L559.9 444.8c-4.4 2.5-6.8 5.7-6.8 9 0 3.2 2.4 6.4 6.7 8.9l256.6 149.1c10.1 5.9 26.5 5.9 36.7 0l258.3-149.1c4.4-2.5 6.8-5.7 6.8-9 0-3.2-2.4-6.4-6.7-8.9L854.8 295.7c-5-2.9-11.6-4.3-18.3-4.3-6.7 0-13.4 1.4-18.3 4.3z'
      fill='url(#filemanage_banner_svg__SVGID_35_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_36_'
      gradientUnits='userSpaceOnUse'
      x1={2043.86}
      y1={313.7}
      x2={1668.56}
      y2={313.7}
      gradientTransform='matrix(-1 0 0 1 2695.26 182)'
    >
      <stop offset={0.117} stopColor='#dddcf9' />
      <stop offset={0.562} stopColor='#fff' />
      <stop offset={0.708} stopColor='#f9f8fe' />
      <stop offset={0.915} stopColor='#e6e6fb' />
      <stop offset={1} stopColor='#dddcf9' />
    </linearGradient>
    <path
      d='M651.6 419.2c0 .5.1.9.2 1.4.1.4.2.7.3 1.1s.2.9.4 1.3c.1.4.3.7.4 1.1.2.5.4.9.6 1.4.2.3.4.6.6 1 .3.5.7 1 1 1.5.4.5.7 1 1.1 1.4l.3.3 1.8 1.8c.4.4.9.7 1.3 1.1.4.3.8.6 1.2 1 .5.4 1 .7 1.6 1 .5.4 1.1.7 1.7 1.1l143.5 83.4c1.3.8 2.7 1.4 4.1 2.1.4.2.8.4 1.2.5 1.1.5 2.3.9 3.4 1.3.4.1.8.3 1.3.4.7.2 1.5.4 2.2.6.6.2 1.1.3 1.7.5.8.2 1.6.4 2.4.5.5.1 1.1.2 1.6.3 1.6.3 3.3.5 5 .7.5.1 1 .1 1.6.1 1 .1 2 .2 2.9.2.6 0 1.3 0 1.9.1h2.4c.7 0 1.3 0 2-.1.7 0 1.5-.1 2.2-.1s1.3-.1 2-.2 1.5-.2 2.2-.2c.7-.1 1.3-.2 2-.3.8-.1 1.5-.3 2.3-.4.6-.1 1.2-.2 1.8-.4.9-.2 1.8-.4 2.7-.7.5-.1 1-.3 1.5-.4 1.6-.5 3.2-1 4.7-1.6.8-.3 1.5-.6 2.2-.9.4-.2.8-.3 1.1-.5 1.1-.5 2.2-1.1 3.2-1.7l144.5-83.4c7.5-4.3 11.8-9.9 12.7-15.6.1-.8.2-1.6.2-2.4l.1 47.4c0 6.5-4.3 13-12.9 18l-144.5 83.4c-1 .6-2.1 1.1-3.2 1.7-.4.2-.8.3-1.1.5-.7.3-1.3.6-2 .9-.1 0-.2.1-.2.1-1.5.6-3.1 1.1-4.7 1.6-.1 0-.2.1-.4.1-.4.1-.8.2-1.1.3-.9.2-1.8.5-2.7.7-.2.1-.5.1-.7.2-.4.1-.7.1-1.1.2-.8.1-1.5.3-2.3.4-.3 0-.6.1-.9.2-.3 0-.7.1-1 .1-.7.1-1.4.2-2.2.2-.4 0-.7.1-1 .1s-.7 0-1 .1c-.7.1-1.5.1-2.2.1-.4 0-.7.1-1.1.1h-5.2c-1 0-2-.1-3-.2-.4 0-.8 0-1.2-.1-.1 0-.2 0-.4-.1-1.7-.2-3.3-.4-5-.7h-.2c-.5-.1-.9-.2-1.4-.3-.8-.2-1.6-.3-2.4-.5-.6-.1-1.1-.3-1.7-.5-.8-.2-1.5-.4-2.2-.6-.2-.1-.3-.1-.5-.1-.3-.1-.5-.2-.8-.3-1.2-.4-2.3-.8-3.4-1.3-.4-.2-.8-.3-1.2-.5-1.4-.6-2.8-1.3-4.1-2.1l-143.5-83.4c-.2-.1-.4-.2-.5-.3-.4-.2-.8-.5-1.1-.8-.5-.3-1.1-.7-1.6-1-.4-.3-.8-.6-1.2-1-.5-.4-.9-.7-1.3-1.1-.1-.1-.2-.1-.3-.2-.6-.5-1.1-1-1.6-1.6l-.3-.3c-.3-.3-.6-.7-.8-1-.1-.1-.2-.3-.3-.4-.4-.5-.7-1-1-1.5-.1-.2-.3-.4-.4-.6-.1-.1-.1-.2-.2-.3-.2-.4-.4-.9-.6-1.4-.1-.3-.3-.5-.4-.8 0-.1 0-.2-.1-.3-.2-.4-.3-.9-.4-1.3l-.3-.9v-.2c-.1-.5-.1-.9-.2-1.4 0-.3-.1-.6-.1-.9v-.4l-.1-47.4c.1.3.1.7.2 1.1z'
      fill='url(#filemanage_banner_svg__SVGID_36_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_37_'
      gradientUnits='userSpaceOnUse'
      x1={2029.16}
      y1={235.75}
      x2={1683.06}
      y2={235.75}
      gradientTransform='matrix(-1 0 0 1 2695.26 182)'
    >
      <stop offset={0.117} stopColor='#5c90fe' />
      <stop offset={0.562} stopColor='#466cf5' />
      <stop offset={0.691} stopColor='#4265f3' />
      <stop offset={0.875} stopColor='#3853ef' />
      <stop offset={1} stopColor='#2e42eb' />
    </linearGradient>
    <path
      d='M838.5 511.9c9 0 17.6-2 23.6-5.5l144.5-83.4c4.3-2.5 5.6-4.8 5.6-5.4 0-.5-1.3-2.8-5.4-5.2L863.3 329c-5.9-3.4-14.5-5.4-23.5-5.4s-17.6 2-23.6 5.5l-144.5 83.4c-4.3 2.5-5.6 4.8-5.6 5.4 0 .5 1.3 2.8 5.4 5.2L815 506.5c5.9 3.5 14.5 5.4 23.5 5.4z'
      fill='url(#filemanage_banner_svg__SVGID_37_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_38_'
        gradientUnits='userSpaceOnUse'
        x1={-497.446}
        y1={190.219}
        x2={-544.133}
        y2={271.082}
        gradientTransform='translate(1366.94 182)'
      >
        <stop offset={0} stopColor='#fff' />
        <stop offset={0.333} stopColor='#f9f8fe' />
        <stop offset={0.805} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M916.4 399.3c4.9 2.8 5.5 7.4 1.2 10.5l-64 48.3c-5.1 3.8-14.8 4.2-20.7.8l-57-32.9c-5.9-3.4-5.3-9.1 1.3-12l83.1-37.2c5.5-2.5 13.3-2.2 18.2.7l37.9 21.8z'
        fill='url(#filemanage_banner_svg__SVGID_38_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_39_'
        gradientUnits='userSpaceOnUse'
        x1={-595.085}
        y1={252.8}
        x2={-446.43}
        y2={252.8}
        gradientTransform='translate(1366.94 182)'
      >
        <stop offset={0} stopColor='#fff' />
        <stop offset={0.333} stopColor='#f9f8fe' />
        <stop offset={0.805} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M920.5 405.3c0 .2 0 .3-.1.5 0 .1 0 .2-.1.3 0 .2-.1.3-.1.5 0 .1-.1.2-.1.2l-.3.6s0 .1-.1.1c-.1.2-.3.5-.5.7 0 .1-.1.1-.1.2-.2.3-.4.5-.7.8l-.1.1c-.2.2-.5.4-.7.6l-64 48.3c-.3.2-.7.5-1 .7h-.1c-.4.2-.8.5-1.3.7-.1.1-.3.1-.4.2-.4.2-.7.3-1.1.4-.2.1-.3.1-.5.2s-.4.1-.6.2c-.2.1-.4.1-.7.2-.2.1-.4.1-.7.1-.2 0-.4.1-.7.1-.5.1-1 .2-1.5.2h-.2c-.5 0-1 .1-1.5.1h-1.4c-.5 0-.9 0-1.4-.1h-.1c-.4 0-.8-.1-1.3-.1-.2 0-.4-.1-.6-.1-.3 0-.5-.1-.8-.1-.5-.1-.9-.2-1.4-.3-.5-.1-1-.3-1.5-.5-.1 0-.2-.1-.3-.1-.6-.3-1.3-.5-1.8-.9l-57-32.9c-2.7-1.6-4.1-3.6-4.1-5.7v3.1c0 2 1.3 4.1 4.1 5.7l57 32.9 1.8.9c.1 0 .1.1.2.1s.1 0 .2.1c.5.2 1 .4 1.5.5.5.1.9.2 1.4.3.2.1.5.1.7.1s.4.1.6.1c.4.1.8.1 1.2.1h.1c.5 0 .9.1 1.4.1h1.4c.5 0 1-.1 1.5-.1h.2c.5-.1 1-.1 1.4-.2h.2c.2 0 .3-.1.5-.1s.4-.1.7-.1c.2-.1.4-.1.7-.2.2-.1.4-.1.6-.2.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1.4-.1.7-.3 1.1-.4.1-.1.3-.1.4-.2.4-.2.9-.4 1.3-.7h.1c.4-.2.7-.4 1-.7l64-48.3c.3-.2.5-.4.7-.6 0 0 0-.1.1-.1.3-.2.5-.5.7-.8l.1-.1v-.1c.2-.2.4-.5.5-.7 0 0 0-.1.1-.1l.3-.6v-.1-.2c.1-.2.1-.3.1-.5v-.2c0-.2 0-.3.1-.5V405.1c.4 0 .4.1.4.2z'
        fill='url(#filemanage_banner_svg__SVGID_39_)'
      />
    </g>
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_40_'
        gradientUnits='userSpaceOnUse'
        x1={-486.834}
        y1={34.827}
        x2={-584.547}
        y2={204.073}
        gradientTransform='translate(1366.94 182)'
      >
        <stop offset={0} stopColor='#fff' />
        <stop offset={0.333} stopColor='#f9f8fe' />
        <stop offset={0.805} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M960.6 263.3c5 2.9 9.1 9.9 9.1 15.7l-.6 204.4c0 5.8-4.1 8.1-9.1 5.2l-258.1-149c-5-2.9-9.1-9.9-9.1-15.7l.6-204.4c0-5.8 4.1-8.1 9.1-5.2l258.1 149z'
        fill='url(#filemanage_banner_svg__SVGID_40_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_41_'
        gradientUnits='userSpaceOnUse'
        x1={-671.04}
        y1={117.8}
        x2={-392.74}
        y2={117.8}
        gradientTransform='translate(1366.94 182)'
      >
        <stop offset={0} stopColor='#fff' />
        <stop offset={0.333} stopColor='#f9f8fe' />
        <stop offset={0.805} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M974.2 276v-.4V275.3v-.2-.4c0-.2 0-.3-.1-.5 0-.1 0-.3-.1-.4 0-.2-.1-.3-.1-.5V273v-.2c0-.2-.1-.3-.1-.5 0-.1-.1-.2-.1-.4v-.1-.1c0-.2-.1-.3-.2-.5s-.1-.3-.2-.5v-.1-.1c0-.1-.1-.2-.1-.3-.1-.1-.1-.3-.2-.4 0-.1-.1-.2-.1-.3v-.1-.1c-.1-.1-.1-.3-.2-.4-.1-.2-.2-.3-.2-.5v-.2c-.1-.1-.1-.3-.2-.4-.1-.2-.2-.3-.3-.5 0-.1-.1-.2-.1-.2s0-.1-.1-.1c-.1-.1-.2-.3-.3-.4-.1-.2-.2-.4-.4-.6 0 0 0-.1-.1-.1-.2-.3-.4-.5-.6-.8-.1-.2-.3-.4-.4-.5-.1-.1-.2-.3-.3-.4-.1-.1-.2-.3-.4-.4-.1-.1-.2-.3-.4-.4l-.1-.1-.1-.1-.3-.3-.4-.4-.1-.1-.1-.1-.2-.2c-.1-.1-.3-.2-.4-.4-.1 0-.1-.1-.2-.1l-.1-.1-.1-.1c-.1-.1-.3-.2-.4-.3-.1-.1-.2-.1-.3-.2h-.1-.1c-.1-.1-.3-.2-.4-.3-.1-.1-.3-.2-.4-.2 0 0-.1 0-.1-.1l-258.1-149c-.1-.1-.2-.1-.3-.2s-.2-.1-.4-.2c-.1 0-.2-.1-.2-.1h-.1-.1c-.1-.1-.2-.1-.4-.2-.1 0-.2-.1-.4-.1h-.1c-.1 0-.2-.1-.3-.1s-.3-.1-.4-.1h-.4c-.1 0-.3 0-.4-.1H702.2c-.2 0-.4 0-.5.1h-.1c-.2 0-.3.1-.5.1-.2.1-.5.2-.7.3l-4.5 2.6c.2-.1.5-.2.7-.3.2-.1.4-.1.5-.2h.1c.2 0 .4-.1.7-.1H698.8c.2 0 .5.1.8.1h.1c.3.1.5.1.8.2.3.1.6.2.9.4h.1c.3.1.6.3.9.5l258.1 149 .9.6h.1c.3.2.6.4.8.6 0 0 .1 0 .1.1.3.2.5.4.8.7l.1.1c.3.2.5.5.8.8l.1.1c.3.3.5.6.8.9.3.3.5.6.7.9l.6.9c.1.2.2.4.4.6.1.2.2.3.3.5 0 .1.1.2.1.2.2.3.3.6.5.8v.1c.2.3.3.7.5 1.1v.1c.2.3.3.7.4 1.1v.1c.1.4.3.7.4 1.1v.1c.1.3.2.7.3 1v.1c.1.4.2.7.2 1.1.1.4.1.7.1 1.1V278.9l-.6 204.4v1.1c0 .3-.1.6-.1.9v.1c-.1.3-.1.5-.2.8v.1c-.1.2-.2.4-.3.7v.1c-.1.2-.2.5-.4.7-.2.3-.4.5-.6.8l-.2.2c-.2.2-.5.4-.8.6l4.5-2.6c.3-.2.5-.4.8-.6l.2-.2c.2-.2.4-.5.6-.8l.3-.6v-.1c.1-.1.1-.3.2-.4 0-.1 0-.1.1-.2v-.2c0-.2.1-.3.1-.5V482.9v-.2c0-.1 0-.3.1-.4v-.4-.4-.4l.6-204.4c.4-.5.4-.6.4-.7z'
        fill='url(#filemanage_banner_svg__SVGID_41_)'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_42_'
      gradientUnits='userSpaceOnUse'
      x1={-673.94}
      y1={97.274}
      x2={-397.14}
      y2={97.274}
      gradientTransform='translate(1366.94 182)'
    >
      <stop offset={0.117} stopColor='#5c90fe' />
      <stop offset={0.562} stopColor='#466cf5' />
      <stop offset={0.691} stopColor='#4265f3' />
      <stop offset={0.875} stopColor='#3853ef' />
      <stop offset={1} stopColor='#2e42eb' />
    </linearGradient>
    <path
      d='M960.7 263.3c5 2.9 9.1 9.9 9.1 15.7l-.5 166.5L693 286l.5-166.5c0-5.8 4.1-8.1 9.1-5.2l258.1 149z'
      fill='url(#filemanage_banner_svg__SVGID_42_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_43_'
        gradientUnits='userSpaceOnUse'
        x1={478.348}
        y1={416.906}
        x2={590.874}
        y2={416.906}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M589.8 600.6c4 2.3 4 6 .1 8.4l-45.8 26.8c-4 2.3-10.5 2.3-14.5 0l-46.3-26.5c-4-2.3-4-6-.1-8.4l45.8-26.8c4-2.3 10.4-2.3 14.5 0l46.3 26.5z'
        fill='url(#filemanage_banner_svg__SVGID_43_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_44_'
        gradientUnits='userSpaceOnUse'
        x1={590.962}
        y1={442.586}
        x2={478.399}
        y2={442.586}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M592.8 605.1v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3s-.1.2-.1.2c0 .1-.1.2-.1.3s-.1.2-.1.2c-.1.1-.2.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.3l-45.8 26.8c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.4.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.2.2H535c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.4-.1-.4-.1-.7-.2-1.1-.4-.1 0-.1 0-.1-.1-.5-.2-1-.4-1.4-.7l-46.3-26.5c-2-1.2-3-2.7-3-4.2v19.5c0 1.5 1 3 3 4.2l46.3 26.5c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.2-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .4-.1.5-.2h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.2.7-.3 1-.5l45.8-26.8s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.3-.2.4-.2.1-.1.2-.2.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4 0-.1.1-.1.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3-19.8c-.1.5-.1.6-.1.7z'
        fill='url(#filemanage_banner_svg__SVGID_44_)'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={572.111}
      y1={416.904}
      x2={495.911}
      y2={416.904}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#dddcf9' />
      <stop offset={0.562} stopColor='#fff' />
      <stop offset={0.708} stopColor='#f9f8fe' />
      <stop offset={0.915} stopColor='#e6e6fb' />
      <stop offset={1} stopColor='#dddcf9' />
    </linearGradient>
    <path
      d='M536.2 627c-1.1 0-1.8-.2-2-.3l-35.6-20.4c-1-.6-1-2 0-2.5l35.2-20.6c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.6 20.4c1 .6 1 2 0 2.5l-35.2 20.6c-.1.1-.8.3-1.9.3z'
      fill='url(#filemanage_banner_svg__SVGID_45_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_46_'
        gradientUnits='userSpaceOnUse'
        x1={478.346}
        y1={381.406}
        x2={590.871}
        y2={381.406}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M589.7 565.1c4 2.3 4 6 .1 8.4L544 600.3c-4 2.3-10.4 2.3-14.5 0l-46.3-26.5c-4-2.3-4-6-.1-8.4l45.8-26.8c4-2.3 10.4-2.3 14.5 0l46.3 26.5z'
        fill='url(#filemanage_banner_svg__SVGID_46_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_47_'
        gradientUnits='userSpaceOnUse'
        x1={590.959}
        y1={407.086}
        x2={478.397}
        y2={407.086}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M592.7 569.6v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3s-.1.2-.1.2c0 .1-.1.2-.1.3s-.1.2-.1.2c-.1.1-.2.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.3L544 600.3c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.4.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.2.2h-3.6c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.4-.1-.4-.1-.7-.2-1.1-.4-.1 0-.1 0-.1-.1-.5-.2-1-.4-1.4-.7l-46.3-26.5c-2-1.2-3-2.7-3-4.2v19.5c0 1.5 1 3 3 4.2l46.3 26.5c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.2-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .4-.1.5-.2h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.2.7-.3 1-.5l45.8-26.8s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.3-.2.4-.2.1-.1.2-.2.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4 0-.1.1-.1.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3-19.8c-.1.5-.1.6-.1.7z'
        fill='url(#filemanage_banner_svg__SVGID_47_)'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_48_'
      gradientUnits='userSpaceOnUse'
      x1={572.208}
      y1={381.405}
      x2={496.009}
      y2={381.405}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0.117} stopColor='#dddcf9' />
      <stop offset={0.562} stopColor='#fff' />
      <stop offset={0.708} stopColor='#f9f8fe' />
      <stop offset={0.915} stopColor='#e6e6fb' />
      <stop offset={1} stopColor='#dddcf9' />
    </linearGradient>
    <path
      d='M536.2 591.5c-1.1 0-1.8-.2-2-.3l-35.6-20.4c-1-.6-1-2 0-2.5l35.2-20.6c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.6 20.4c1 .6 1 2 0 2.5l-35.2 20.6c-.2 0-.9.3-1.9.3z'
      fill='url(#filemanage_banner_svg__SVGID_48_)'
    />
    <g className='filemanage_banner_svg__st1'>
      <linearGradient
        id='filemanage_banner_svg__SVGID_49_'
        gradientUnits='userSpaceOnUse'
        x1={478.232}
        y1={350.106}
        x2={590.757}
        y2={350.106}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M589.5 533.8c4 2.3 4 6 .1 8.4L543.8 569c-4 2.3-10.5 2.3-14.5 0L483 542.5c-4-2.3-4-6-.1-8.4l45.8-26.8c4-2.3 10.4-2.3 14.5 0l46.3 26.5z'
        fill='url(#filemanage_banner_svg__SVGID_49_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_50_'
        gradientUnits='userSpaceOnUse'
        x1={590.769}
        y1={375.986}
        x2={478.207}
        y2={375.986}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M592.5 538.3v.3c0 .1 0 .2-.1.3 0 .1-.1.2-.1.3s-.1.2-.1.2c0 .1-.1.2-.1.3s-.1.2-.1.2c-.1.1-.2.2-.2.4l-.2.2c-.1.2-.3.3-.4.5l-.2.2-.3.3c-.1.1-.2.2-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.3L543.8 569c-.3.2-.6.3-1 .5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.4.1-.5.2-.1 0-.3.1-.4.1-.2 0-.4.1-.6.1-.1 0-.3.1-.4.1-.4.1-.8.1-1.2.2h-3.6c-.2 0-.3 0-.5-.1-.2 0-.3 0-.5-.1-.2 0-.4-.1-.5-.1s-.3-.1-.4-.1c-.2 0-.4-.1-.6-.2-.1 0-.2-.1-.4-.1-.4-.1-.7-.2-1.1-.4-.1 0-.1 0-.1-.1-.5-.2-1-.4-1.4-.7L483 542.5c-2-1.2-3-2.7-3-4.2v19.5c0 1.5 1 3 3 4.2l46.3 26.5c.4.2.9.5 1.4.7h.2c.4.1.7.3 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.2h.5c.2 0 .4.1.5.1h.4c.2 0 .3 0 .5.1h3.6c.4 0 .8-.1 1.2-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.1 0 .3-.1.4-.1.2 0 .4-.1.5-.2h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.2.7-.3 1-.5l45.8-26.8s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.3-.2.4-.2.1-.1.2-.2.3-.2l.3-.3.1-.1.1-.1c.2-.2.3-.3.4-.5l.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.4 0-.1.1-.1.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3-19.8c-.2.3-.2.4-.2.5z'
        fill='url(#filemanage_banner_svg__SVGID_50_)'
      />
    </g>
    <path
      className='filemanage_banner_svg__st28'
      d='M536 560.2c-1.1 0-1.8-.2-2-.3l-35.6-20.4c-1-.6-1-2 0-2.5l35.2-20.6c.1-.1.8-.3 1.9-.3 1.1 0 1.8.2 2 .3l35.6 20.4c1 .6 1 2 0 2.5l-35.2 20.6c-.2.1-.9.3-1.9.3z'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_51_'
      gradientUnits='userSpaceOnUse'
      x1={533.897}
      y1={364.655}
      x2={533.897}
      y2={240.33}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M507.6 428.5l.4 108.2c0 .4.2.7.5.9l25.9 14.9c.1.1.6.2 1.5.2.8 0 1.3-.2 1.4-.2l25.6-15c.4-.2.5-.6.5-1l-.4-108.2-55.4.2z'
      fill='url(#filemanage_banner_svg__SVGID_51_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_52_'
      gradientUnits='userSpaceOnUse'
      x1={533.971}
      y1={372.155}
      x2={533.971}
      y2={258.15}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M497.4 446.3l.3 91.9c0 .5.2 1 .7 1.3l35.6 20.4c.2.1.9.3 2 .3s1.8-.2 1.9-.3l35.2-20.6c.5-.3.7-.8.7-1.3l-.3-91.9-76.1.2z'
      fill='url(#filemanage_banner_svg__SVGID_52_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={536.015}
      y1={343.938}
      x2={536.015}
      y2={274.165}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#fa917a' />
      <stop offset={0.393} stopColor='#f89980' />
      <stop offset={0.995} stopColor='#f79c82' />
    </linearGradient>
    <path
      d='M563.9 509.5c0-.4 0-.8-.1-1.2 0-.4-.1-.8-.1-1.3 0-.4-.1-.9-.1-1.3-.1-.4-.1-.9-.2-1.3-.1-.3-.1-.7-.2-1 0-.1 0-.2-.1-.3-.1-.5-.2-.9-.3-1.4s-.2-.9-.3-1.4-.2-.9-.4-1.4c-.1-.5-.3-.9-.4-1.4-.1-.5-.3-.9-.4-1.4-.1-.4-.3-.9-.5-1.3s-.3-.9-.5-1.3-.3-.8-.5-1.2c-.2-.4-.4-.8-.5-1.2l-.6-1.2c-.2-.5-.4-.9-.7-1.4s-.5-1-.8-1.5c-.4-.8-.9-1.6-1.3-2.3-.7-1.2-1.4-2.3-2.2-3.4l-1.5-2.1c-.4-.5-.7-1-1.1-1.4-.3-.4-.7-.8-1-1.2-.3-.4-.7-.8-1-1.2-.3-.4-.7-.8-1-1.1-.4-.4-.7-.8-1.1-1.1l-1.1-1.1c-.4-.4-.8-.7-1.2-1.1-.4-.3-.8-.7-1.2-1l-1.2-.9-1.2-.9c-.4-.3-.7-.5-1.1-.8-.4-.2-.7-.5-1.1-.7-.4-.2-.7-.4-1.1-.7-.1 0-.2-.1-.2-.1-.3-.2-.5-.3-.8-.5s-.7-.4-1-.5c-.3-.2-.7-.3-1-.5s-.7-.3-1-.4c-.4-.1-.7-.3-1.1-.4-.3-.1-.7-.2-1-.3h-.1c-.4-.1-.7-.2-1.1-.3-.4-.1-.7-.2-1.1-.2-.4-.1-.8-.1-1.1-.2-.4-.1-.8-.1-1.2-.1h-1.2c-.5 0-.9 0-1.3.1-.5 0-1 .1-1.5.2-.6.1-1.2.3-1.8.5-.7.3-1.4.6-2.1 1l-7.9 4.6c.7-.4 1.4-.7 2.1-1 .6-.2 1.2-.4 1.8-.5.5-.1 1-.2 1.5-.2.4 0 .9-.1 1.3-.1h1.2c.4 0 .8.1 1.2.1s.8.1 1.1.2c.4.1.7.1 1.1.2.4.1.7.2 1.1.3h.1c.3.1.7.2 1 .3.4.1.7.3 1.1.4.3.1.7.3 1 .4.3.2.7.3 1 .5.4.2.7.4 1 .5s.5.3.8.5c.1 0 .2.1.2.1.4.2.7.4 1.1.7.4.2.7.5 1.1.7.4.3.7.5 1.1.8l1.2.9 1.2.9c.4.3.8.7 1.2 1s.8.7 1.2 1.1l1.1 1.1 1.1 1.1c.3.4.7.7 1 1.1s.7.8 1 1.2c.3.4.7.8 1 1.2.4.5.7.9 1.1 1.4l1.5 2.1c.8 1.1 1.5 2.2 2.2 3.4.5.8.9 1.5 1.3 2.3.3.5.5 1 .8 1.5.2.4.4.9.7 1.4l.6 1.2c.2.4.4.8.5 1.2.2.4.3.8.5 1.2.2.4.3.8.5 1.3.2.4.3.9.5 1.3.2.5.3.9.4 1.4.1.5.3.9.4 1.4.1.5.3.9.4 1.4.1.5.2.9.3 1.4s.2.9.3 1.4c0 .1 0 .2.1.3.1.4.1.7.2 1 .1.4.1.9.2 1.3s.1.9.1 1.3.1.8.1 1.3c0 .4 0 .8.1 1.2v2.4c0 .4 0 .8-.1 1.2 0 .4-.1.8-.1 1.2-.1.4-.1.8-.2 1.2-.1.4-.2.9-.3 1.3-.1.5-.2.9-.3 1.3-.1.5-.3.9-.5 1.4l-.6 1.5c-.3.6-.6 1.2-1 1.8-1.2 1.9-2.7 3.4-4.5 4.4l7.9-4.6c1.8-1 3.3-2.5 4.5-4.4.4-.6.7-1.2 1-1.8l.6-1.5c.2-.4.3-.9.5-1.4.1-.4.2-.9.3-1.3.1-.4.2-.8.3-1.3.1-.4.1-.8.2-1.2.1-.4.1-.8.1-1.2s.1-.8.1-1.2v-1.2c.1-.4.1-.8 0-1.2z'
      fill='url(#filemanage_banner_svg__SVGID_53_)'
    />
    <g>
      <linearGradient
        id='filemanage_banner_svg__SVGID_54_'
        gradientUnits='userSpaceOnUse'
        x1={502.291}
        y1={312.142}
        x2={554.307}
        y2={312.142}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0} stopColor='#fa917a' />
        <stop offset={0.913} stopColor='#f79b81' />
        <stop offset={0.995} stopColor='#f79c82' />
      </linearGradient>
      <path
        d='M530 470.4c11.8 6.8 22.5 22.2 25.3 37.5 3.3 18-5.4 29.1-19.4 24.8-2-.6-4-1.5-6-2.6-11.8-6.8-22.5-22.2-25.3-37.6-3.3-17.9 5.4-29.1 19.4-24.8 2 .7 4 1.6 6 2.7z'
        fill='url(#filemanage_banner_svg__SVGID_54_)'
      />
    </g>
    <g>
      <path
        className='filemanage_banner_svg__st33'
        d='M530.1 475.1c10 5.7 19 18.8 21.4 31.8 2.8 15.2-4.5 24.6-16.4 21-1.7-.5-3.4-1.3-5.1-2.2-10-5.7-19-18.8-21.4-31.8-2.8-15.2 4.5-24.6 16.4-21 1.7.5 3.4 1.3 5.1 2.2z'
      />
    </g>
    <g>
      <path
        d='M530.1 476.5c9.5 5.4 18 17.8 20.3 30.1 2.6 14.4-4.3 23.3-15.5 19.9-1.6-.5-3.2-1.2-4.8-2.1-9.5-5.4-18-17.8-20.3-30.1-2.6-14.4 4.3-23.3 15.5-19.9 1.6.4 3.2 1.2 4.8 2.1z'
        fill='#f79c82'
      />
    </g>
    <path
      className='filemanage_banner_svg__st33'
      d='M529.9 489.7v-.9c0-1.1-.8-2.5-1.8-3.1l-8.5-4.9c-1-.6-1.8-.1-1.8 1v1.9l-.8-.5c-1-.6-1.8-.1-1.8 1v16.6c0 1.2.8 2.6 1.8 3.2 0 0 22.9 13.2 23.2 13.4 1.4.8 2.6.2 2.6-1.5v-18.8l-12.9-7.4zm11.3 25.3c0 .5-.4.8-.9.5s-.9-.9-.9-1.5V499.1l-13-7.5v-.9c0-1.1-.8-2.5-1.8-3.1l-5.1-3v-1.9s0-.1.1 0l8.5 4.9.1.1v2.9l1.7 1 11.2 6.5V515h.1z'
    />
    <g>
      <g className='filemanage_banner_svg__st1'>
        <linearGradient
          id='filemanage_banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={1025.928}
          y1={428.852}
          x2={1133.628}
          y2={428.852}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1132.7 611.3c3.8 2.2 3.9 5.8.1 8l-43.9 25.6c-3.8 2.2-10 2.2-13.8 0l-44.3-25.4c-3.8-2.2-3.9-5.8-.1-8l43.9-25.6c3.8-2.2 10-2.2 13.8 0l44.3 25.4z'
          fill='url(#filemanage_banner_svg__SVGID_55_)'
        />
        <linearGradient
          id='filemanage_banner_svg__SVGID_56_'
          gradientUnits='userSpaceOnUse'
          x1={1133.666}
          y1={453.23}
          x2={1025.9}
          y2={453.23}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1135.6 615.6v.3c0 .1 0 .2-.1.3 0 .1 0 .2-.1.3 0 .1-.1.2-.1.2 0 .1-.1.2-.1.3s-.1.1-.1.2c-.1.1-.1.2-.2.3 0 .1-.1.1-.2.2-.1.2-.3.3-.4.4l-.2.2c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-43.9 25.6c-.3.2-.6.3-.9.5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.1-.1 0-.3.1-.4.1-.2 0-.4.1-.5.1s-.2.1-.4.1c-.4.1-.7.1-1.1.2h-3.3c-.2 0-.3 0-.5-.1-.1 0-.3 0-.4-.1-.2 0-.3-.1-.5-.1-.1 0-.3-.1-.4-.1-.2 0-.4-.1-.6-.1-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.3-.6l-44.3-25.4c-1.9-1.1-2.9-2.6-2.9-4v18.6c0 1.5 1 2.9 2.9 4l44.3 25.4c.4.2.9.4 1.3.6h.2c.3.1.7.2 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.1h.4c.2 0 .3.1.5.1h.4c.2 0 .3 0 .5.1H1084c.4 0 .7-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.5-.1s.3-.1.4-.1c.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3.9-.5l43.9-25.6s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.2-.2.4-.2.1-.1.2-.1.3-.2.1-.1.2-.2.3-.2l.1-.1.1-.1.4-.4.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.3l.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3V614.8c-.4.6-.4.7-.4.8z'
          fill='url(#filemanage_banner_svg__SVGID_56_)'
        />
      </g>
      <linearGradient
        id='filemanage_banner_svg__SVGID_57_'
        gradientUnits='userSpaceOnUse'
        x1={1115.72}
        y1={428.851}
        x2={1042.77}
        y2={428.851}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0.117} stopColor='#dddcf9' />
        <stop offset={0.562} stopColor='#fff' />
        <stop offset={0.708} stopColor='#f9f8fe' />
        <stop offset={0.915} stopColor='#e6e6fb' />
        <stop offset={1} stopColor='#dddcf9' />
      </linearGradient>
      <path
        d='M1081.5 636.5c-1.1 0-1.8-.2-1.9-.3l-34.1-19.5c-.9-.5-.9-1.9 0-2.4l33.7-19.7c.1-.1.8-.3 1.9-.3s1.8.2 1.9.3l34.1 19.5c.9.5.9 1.9 0 2.4l-33.7 19.7c-.2.1-.9.3-1.9.3z'
        fill='url(#filemanage_banner_svg__SVGID_57_)'
      />
    </g>
    <g>
      <g className='filemanage_banner_svg__st1'>
        <linearGradient
          id='filemanage_banner_svg__SVGID_58_'
          gradientUnits='userSpaceOnUse'
          x1={1025.921}
          y1={394.852}
          x2={1133.622}
          y2={394.852}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1132.6 577.3c3.8 2.2 3.9 5.8.1 8l-43.9 25.6c-3.8 2.2-10 2.2-13.8 0l-44.3-25.4c-3.8-2.2-3.9-5.8-.1-8l43.9-25.6c3.8-2.2 10-2.2 13.8 0l44.3 25.4z'
          fill='url(#filemanage_banner_svg__SVGID_58_)'
        />
        <linearGradient
          id='filemanage_banner_svg__SVGID_59_'
          gradientUnits='userSpaceOnUse'
          x1={1133.666}
          y1={419.23}
          x2={1025.9}
          y2={419.23}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1135.5 581.6v.3c0 .1 0 .2-.1.3 0 .1 0 .2-.1.3 0 .1-.1.2-.1.2 0 .1-.1.2-.1.3s-.1.1-.1.2c-.1.1-.1.2-.2.3 0 .1-.1.1-.2.2-.1.2-.3.3-.4.4l-.2.2c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-43.9 25.6c-.3.2-.6.3-.9.5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.1-.1 0-.3.1-.4.1-.2 0-.4.1-.5.1s-.2.1-.4.1c-.4.1-.7.1-1.1.2h-3.3c-.2 0-.3 0-.5-.1-.1 0-.3 0-.4-.1-.2 0-.3-.1-.5-.1-.1 0-.3-.1-.4-.1-.2 0-.4-.1-.6-.1-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.3-.6l-44.3-25.4c-1.9-1.1-2.9-2.6-2.9-4v18.6c0 1.5 1 2.9 2.9 4l44.3 25.4c.4.2.9.4 1.3.6h.2c.3.1.7.2 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.1h.4c.2 0 .3.1.5.1h.4c.2 0 .3 0 .5.1H1083.9c.4 0 .7-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.5-.1s.3-.1.4-.1c.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3.9-.5l43.9-25.6s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.2-.2.4-.2.1-.1.2-.1.3-.2.1-.1.2-.2.3-.2l.1-.1.1-.1.4-.4.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.3l.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3V580.8c-.4.6-.4.7-.4.8z'
          fill='url(#filemanage_banner_svg__SVGID_59_)'
        />
      </g>
      <path
        className='filemanage_banner_svg__st28'
        d='M1081.4 602.5c-1.1 0-1.8-.2-1.9-.3l-34.1-19.5c-.9-.5-.9-1.9 0-2.4l33.7-19.7c.1-.1.8-.3 1.9-.3s1.8.2 1.9.3l34.1 19.5c.9.5.9 1.9 0 2.4l-33.7 19.7c-.2.1-.9.3-1.9.3z'
      />
    </g>
    <g>
      <g className='filemanage_banner_svg__st1'>
        <linearGradient
          id='filemanage_banner_svg__SVGID_60_'
          gradientUnits='userSpaceOnUse'
          x1={1025.311}
          y1={361.951}
          x2={1133.012}
          y2={361.951}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1131.9 544.4c3.8 2.2 3.9 5.8.1 8l-43.9 25.6c-3.8 2.2-10 2.2-13.8 0l-44.3-25.4c-3.8-2.2-3.9-5.8-.1-8l43.9-25.6c3.8-2.2 10-2.2 13.8 0l44.3 25.4z'
          fill='url(#filemanage_banner_svg__SVGID_60_)'
        />
        <linearGradient
          id='filemanage_banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={1133.041}
          y1={386.328}
          x2={1025.275}
          y2={386.328}
          gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
        >
          <stop offset={0.117} stopColor='#dddcf9' />
          <stop offset={0.562} stopColor='#fff' />
          <stop offset={0.708} stopColor='#f9f8fe' />
          <stop offset={0.915} stopColor='#e6e6fb' />
          <stop offset={1} stopColor='#dddcf9' />
        </linearGradient>
        <path
          d='M1134.8 548.7v.3c0 .1 0 .2-.1.3 0 .1 0 .2-.1.3 0 .1-.1.2-.1.2 0 .1-.1.2-.1.3s-.1.1-.1.2c-.1.1-.1.2-.2.3 0 .1-.1.1-.2.2-.1.2-.3.3-.4.4l-.2.2c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.2-.1.1-.2.2-.4.2-.1.1-.2.2-.4.2l-43.9 25.6c-.3.2-.6.3-.9.5-.1 0-.2.1-.3.1-.3.1-.5.2-.8.3-.1 0-.2.1-.3.1-.2.1-.3.1-.5.1-.1 0-.3.1-.4.1-.2 0-.4.1-.5.1s-.2.1-.4.1c-.4.1-.7.1-1.1.2h-3.3c-.2 0-.3 0-.5-.1-.1 0-.3 0-.4-.1-.2 0-.3-.1-.5-.1-.1 0-.3-.1-.4-.1-.2 0-.4-.1-.6-.1-.1 0-.2-.1-.3-.1-.4-.1-.7-.2-1.1-.4 0 0-.1 0-.1-.1-.5-.2-.9-.4-1.3-.6l-44.3-25.4c-1.9-1.1-2.9-2.6-2.9-4v18.6c0 1.5 1 2.9 2.9 4l44.3 25.4c.4.2.9.4 1.3.6h.2c.3.1.7.2 1.1.4h.1c.1 0 .2 0 .3.1.2.1.4.1.6.1h.4c.2 0 .3.1.5.1h.4c.2 0 .3 0 .5.1H1083.2c.4 0 .7-.1 1.1-.2h.1c.1 0 .2 0 .3-.1.2 0 .4-.1.5-.1s.3-.1.4-.1c.2 0 .3-.1.5-.1h.1c.1 0 .1 0 .2-.1.3-.1.5-.2.8-.3.1 0 .2-.1.3-.1.3-.1.6-.3.9-.5l43.9-25.6s.1 0 .1-.1c.1-.1.2-.1.3-.2.1-.1.2-.2.4-.2.1-.1.2-.1.3-.2.1-.1.2-.2.3-.2l.1-.1.1-.1.4-.4.1-.1s0-.1.1-.1c.1-.1.2-.2.2-.3l.1-.1v-.1c.1-.1.1-.2.1-.3s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3s0-.1.1-.2v-.1-.3V547.9c-.4.6-.4.7-.4.8z'
          fill='url(#filemanage_banner_svg__SVGID_61_)'
        />
      </g>
      <path
        className='filemanage_banner_svg__st28'
        d='M1080.7 569.7c-1.1 0-1.8-.2-1.9-.3l-34.1-19.5c-.9-.5-.9-1.9 0-2.4l33.7-19.7c.1-.1.8-.3 1.9-.3s1.8.2 1.9.3l34.1 19.5c.9.5.9 1.9 0 2.4l-33.7 19.7c-.2 0-.9.3-1.9.3z'
      />
    </g>
    <linearGradient
      id='filemanage_banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={1079.36}
      y1={366.766}
      x2={1079.36}
      y2={223.875}
      gradientTransform='matrix(1 -.00274 .00226 .8232 .99 264.341)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M1054.3 445.8l.3 104.9c0 .3.2.6.5.7l24.8 11.7c.1 0 .6.2 1.4.2s1.3-.1 1.4-.2l24.5-11.8c.4-.2.5-.5.5-.8l-.3-104.9-53.1.2z'
      fill='url(#filemanage_banner_svg__SVGID_62_)'
    />
    <linearGradient
      id='filemanage_banner_svg__SVGID_63_'
      gradientUnits='userSpaceOnUse'
      x1={1079.43}
      y1={383.952}
      x2={1079.43}
      y2={274.752}
      gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
    >
      <stop offset={0} stopColor='#5c90fe' />
      <stop offset={0.287} stopColor='#5180fc' stopOpacity={0.713} />
      <stop offset={0.613} stopColor='#4a75fa' stopOpacity={0.387} />
      <stop offset={1} stopColor='#4872fa' stopOpacity={0} />
    </linearGradient>
    <path
      d='M1044.5 461.5l.2 88c0 .5.2.9.7 1.2l34.1 19.5c.2.1.8.3 1.9.3 1 0 1.7-.2 1.9-.3l33.7-19.7c.5-.3.7-.8.7-1.3l-.2-88-73 .3z'
      fill='url(#filemanage_banner_svg__SVGID_63_)'
    />
    <g>
      <linearGradient
        id='filemanage_banner_svg__SVGID_64_'
        gradientUnits='userSpaceOnUse'
        x1={1068.441}
        y1={291.312}
        x2={1103.533}
        y2={352.093}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0} stopColor='#69ecda' />
        <stop offset={0.183} stopColor='#63e2db' />
        <stop offset={0.493} stopColor='#52c8dd' />
        <stop offset={0.889} stopColor='#369de0' />
        <stop offset={0.995} stopColor='#2e90e1' />
      </linearGradient>
      <path
        d='M1107.7 524.3c0-.4 0-.8-.1-1.2 0-.4-.1-.8-.1-1.2s-.1-.8-.1-1.2c-.1-.4-.1-.8-.2-1.3-.1-.3-.1-.7-.2-1 0-.1 0-.2-.1-.3-.1-.4-.2-.9-.3-1.3-.1-.4-.2-.9-.3-1.3-.1-.4-.2-.9-.4-1.3-.1-.4-.3-.9-.4-1.3-.1-.4-.3-.9-.4-1.3-.1-.4-.3-.8-.4-1.3-.1-.4-.3-.8-.5-1.2-.2-.4-.3-.8-.5-1.2-.2-.4-.3-.8-.5-1.2-.2-.4-.4-.8-.5-1.2-.2-.4-.4-.9-.6-1.3-.2-.5-.5-1-.8-1.5-.4-.8-.8-1.5-1.3-2.2-.7-1.1-1.3-2.2-2.1-3.2-.5-.7-.9-1.4-1.4-2-.3-.5-.7-.9-1-1.4-.3-.4-.6-.8-1-1.2-.3-.4-.6-.8-1-1.1-.3-.4-.7-.7-1-1.1s-.7-.7-1-1.1c-.4-.4-.7-.7-1.1-1s-.7-.7-1.1-1-.7-.7-1.1-1-.7-.6-1.1-.9-.7-.6-1.1-.8c-.4-.3-.7-.5-1.1-.7-.3-.2-.7-.5-1-.7s-.7-.4-1-.6c-.1 0-.2-.1-.2-.1-.3-.1-.5-.3-.8-.4-.3-.2-.7-.4-1-.5s-2.3-.9-3-1.3c-.1 0-.2-.1-.2-.1-.2-.1-.4-.1-.6-.2h-.1c-.4-.1-.7-.2-1.1-.3-.4-.1-.7-.2-1-.2-.4-.1-.7-.1-1.1-.2-.4 0-.8-.1-1.1-.1h-1.2c-.4 0-.9 0-1.3.1-.5 0-1 .1-1.4.2-.6.1-1.2.3-1.7.5-.7.3-1.4.6-2 .9l-7.6 4.4c.6-.4 1.3-.7 2-.9.6-.2 1.1-.4 1.7-.5.5-.1.9-.2 1.4-.2.4 0 .8-.1 1.3-.1h1.2c.4 0 .7.1 1.1.1s.7.1 1.1.2c.3.1.7.1 1 .2s.7.2 1.1.3h.1c.3.1.6.2 1 .3-1.8-.3.7.2 1 .4.3.1.7.3 1 .4s.7.3 1 .5.7.3 1 .5c.3.1.5.3.8.4.1 0 .2.1.2.1.3.2.7.4 1 .6.4.2.7.5 1 .7.4.2.7.5 1.1.7.4.3.7.5 1.1.8s.7.6 1.1.9.7.6 1.1 1c.4.3.7.7 1.1 1s.7.7 1.1 1c.3.4.7.7 1 1.1s.7.7 1 1.1.6.7 1 1.1c.3.4.7.8 1 1.2.4.4.7.9 1 1.4.5.7 1 1.3 1.4 2 .7 1.1 1.4 2.1 2.1 3.2.4.7.9 1.5 1.3 2.2.3.5.5 1 .8 1.5.2.4.4.9.6 1.3s.4.8.5 1.2c.2.4.3.8.5 1.2.2.4.3.8.5 1.2s.3.8.5 1.2.3.8.4 1.3c.1.4.3.9.4 1.3.1.4.3.9.4 1.3.1.4.2.9.4 1.3.1.4.2.9.3 1.3.1.4.2.9.3 1.3 0 .1 0 .2.1.3.1.3.1.7.2 1 .1.4.1.8.2 1.3.1.4.1.8.1 1.2s.1.8.1 1.2 0 .8.1 1.2v2.4c0 .4 0 .8-.1 1.2 0 .4-.1.8-.1 1.2s-.1.8-.2 1.2-.2.8-.2 1.2c-.1.4-.2.9-.3 1.3-.1.5-.3.9-.4 1.3-.2.5-.4 1-.6 1.4-.3.6-.6 1.2-.9 1.7-1.1 1.8-2.6 3.2-4.3 4.2l7.6-4.4c1.7-1 3.2-2.4 4.3-4.2.3-.5.7-1.1.9-1.7.2-.5.4-.9.6-1.4.2-.4.3-.9.4-1.3.1-.4.2-.8.3-1.3.1-.4.2-.8.2-1.2.1-.4.1-.8.2-1.2 0-.4.1-.8.1-1.2s.1-.8.1-1.2v-1.2c-.2-.4-.2-.8-.2-1.2z'
        fill='url(#filemanage_banner_svg__SVGID_64_)'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_65_'
        gradientUnits='userSpaceOnUse'
        x1={1048.562}
        y1={328.885}
        x2={1098.355}
        y2={328.885}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0} stopColor='#69ecda' />
        <stop offset={0.183} stopColor='#63e2db' />
        <stop offset={0.493} stopColor='#52c8dd' />
        <stop offset={0.889} stopColor='#369de0' />
        <stop offset={0.995} stopColor='#2e90e1' />
      </linearGradient>
      <path
        d='M1075.2 486.9c11.3 6.5 21.5 21.2 24.2 35.9 3.2 17.2-5.1 27.8-18.5 23.8-1.9-.6-3.9-1.4-5.7-2.5-11.3-6.5-21.5-21.2-24.2-36-3.2-17.2 5.1-27.8 18.5-23.8 1.9.7 3.8 1.5 5.7 2.6z'
        fill='url(#filemanage_banner_svg__SVGID_65_)'
      />
      <path
        className='filemanage_banner_svg__st33'
        d='M1075.3 491.4c9.6 5.5 18.2 18 20.5 30.4 2.7 14.5-4.4 23.5-15.7 20.1-1.6-.5-3.3-1.2-4.9-2.1-9.6-5.5-18.2-18-20.5-30.4-2.7-14.5 4.4-23.6 15.7-20.1 1.6.5 3.3 1.2 4.9 2.1z'
      />
      <linearGradient
        id='filemanage_banner_svg__SVGID_66_'
        gradientUnits='userSpaceOnUse'
        x1={1053.605}
        y1={329.035}
        x2={1093.499}
        y2={329.035}
        gradientTransform='matrix(1 -.00274 .00274 1 .795 189.511)'
      >
        <stop offset={0} stopColor='#69ecda' />
        <stop offset={0.183} stopColor='#63e2db' />
        <stop offset={0.493} stopColor='#52c8dd' />
        <stop offset={0.889} stopColor='#369de0' />
        <stop offset={0.995} stopColor='#2e90e1' />
      </linearGradient>
      <path
        d='M1075.3 492.7c9 5.2 17.2 17 19.4 28.8 2.5 13.8-4.1 22.3-14.9 19-1.5-.5-3.1-1.2-4.6-2-9-5.2-17.2-17-19.4-28.8-2.5-13.8 4.1-22.3 14.9-19 1.5.5 3.1 1.2 4.6 2z'
        fill='url(#filemanage_banner_svg__SVGID_66_)'
      />
      <path className='filemanage_banner_svg__st33' d='M1070.8 512.4v17l8.9 5.1v-16.9l6.4 3.6-10.8-22.4-10.9 9.9z' />
    </g>
    <g opacity={0.54}>
      <path
        className='filemanage_banner_svg__st33'
        d='M827.9 243.9c4.3 2.5 7.7 8.5 7.7 13.4l-.2 67.6c0 4.9-3.5 6.9-7.8 4.4l-103.9-60c-4.3-2.5-7.8-8.5-7.7-13.4l.2-67.6c0-4.9 3.5-6.9 7.8-4.4l103.9 60z'
      />
    </g>
    <g>
      <path className='filemanage_banner_svg__st71' d='M731.1 185.2l2.5 1.6' />
      <path className='filemanage_banner_svg__st72' d='M739 190.1l51.5 31.3' />
      <path className='filemanage_banner_svg__st71' d='M793.2 223.1l2.5 1.5' />
    </g>
    <g>
      <path className='filemanage_banner_svg__st71' d='M731.9 198.9l2.6 1.5' />
      <path className='filemanage_banner_svg__st72' d='M739.9 203.7l51.4 31.4' />
      <path className='filemanage_banner_svg__st71' d='M794.1 236.7l2.5 1.6' />
    </g>
    <g>
      <path className='filemanage_banner_svg__st71' d='M731.9 212.5l2.6 1.6' />
      <path className='filemanage_banner_svg__st72' d='M739.9 217.4l51.4 31.3' />
      <path className='filemanage_banner_svg__st71' d='M794.1 250.3l2.5 1.6' />
    </g>
    <g>
      <path className='filemanage_banner_svg__st71' d='M731.9 226.2l2.6 1.5' />
      <path className='filemanage_banner_svg__st72' d='M739.9 231l51.4 31.3' />
      <path className='filemanage_banner_svg__st71' d='M794.1 264l2.5 1.5' />
    </g>
    <g>
      <path className='filemanage_banner_svg__st71' d='M731.9 239.8l2.6 1.6' />
      <path className='filemanage_banner_svg__st72' d='M739.9 244.7l51.4 31.3' />
      <path className='filemanage_banner_svg__st71' d='M794.1 277.6l2.5 1.6' />
    </g>
    <g>
      <linearGradient
        id='filemanage_banner_svg__SVGID_67_'
        gradientUnits='userSpaceOnUse'
        x1={831.55}
        y1={189.893}
        x2={831.55}
        y2={21.207}
        gradientTransform='translate(0 182)'
      >
        <stop offset={0} stopColor='#f0eeff' stopOpacity={0.8} />
        <stop offset={0.538} stopColor='#f6f5ff' stopOpacity={0.908} />
        <stop offset={1} stopColor='#fff' />
      </linearGradient>
      <path
        d='M891.1 273c4.9 2.8 8.9 9.7 8.8 15.3l-.2 77.3c0 5.6-4 7.9-8.9 5.1L772 302.1c-4.9-2.8-8.9-9.7-8.8-15.3l.2-77.3c0-5.6 4-7.9 8.9-5.1L891.1 273z'
        fill='url(#filemanage_banner_svg__SVGID_67_)'
      />
    </g>
    <g>
      <path
        className='filemanage_banner_svg__st74'
        d='M791.4 288.5l.1-41.7 19.6 11.3v4.5l-14.9-8.5v13.8l13.7 7.9v4.5l-13.7-7.9-.1 18.8zM818.6 262.4c1.8 1 2.9 3.2 2.9 5.1s-1.1 2.7-3 1.6c-1.7-1-2.8-3.1-2.8-5-.1-1.9 1.1-2.7 2.9-1.7zM816.2 272.8l4.7 2.8-.1 29.9-4.7-2.7zM828.7 310.1l.1-44 4.7 2.8-.1 43.9zM851.8 292.7c8.5 4.9 10.7 14.8 10.7 20.3 0 1.1-.1 1.9-.2 2.4L844 304.8c.1 7.4 4.2 12.8 8.9 15.6 3.4 2 5.4 2.5 7.2 2.6l.8 4.4c-1.7-.1-4.5-.7-8.7-3.1-8-4.6-12.8-13.5-12.8-22.5.2-9 4.8-13.5 12.4-9.1zM858 309c.1-3.4-1.2-9.6-6.5-12.6-4.8-2.8-6.9 1.1-7.3 4.7L858 309'
      />
    </g>
  </svg>
)

export default SvgFilemanageBanner
