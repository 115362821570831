import React from 'react'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, H2, H3, Wrapper } from '@raysync/common/components'
import { LOCALES, LocaleService } from '@raysync/common/services'
import Layout from '../../components/layout'
import {
  SectionDesc,
  MobileMainItem,
  FeatureSection,
  Section,
  SolutionDivider,
  FeatureContainer,
  MainItem,
} from './atoms'
import { useMedia, useHover } from '@raysync/common/hooks'
import ImgZh1 from './images/zh/1.png'
import ImgZh2 from './images/zh/2.png'
import ImgZh3 from './images/zh/3.png'
import ImgZh4 from './images/zh/4.png'
import ImgZh5 from './images/zh/5.png'
import ImgZh6 from './images/zh/6.png'
import ImgZh7 from './images/zh/7.png'
import ImgZh8 from './images/zh/8.png'
import ImgZh9 from './images/zh/9.png'
import ImgZh10 from './images/zh/10.png'
import ImgEn1 from './images/en/1.png'
import ImgEn2 from './images/en/2.png'
import ImgEn3 from './images/en/3.png'
import ImgEn4 from './images/en/4.png'
import ImgEn5 from './images/en/5.png'
import ImgEn6 from './images/en/6.png'
import ImgEn7 from './images/en/7.png'
import ImgEn8 from './images/en/8.png'
import ImgEn9 from './images/en/9.png'
import ImgEn10 from './images/en/10.png'

import Banner from '@raysync/common/components/svg/solutions/applications/filemanage-banner'
const FuncItem = ({ onTrigger, children, active }) => {
  const ref = React.useRef()
  useHover(ref, onTrigger, true)
  return (
    <MainItem ref={ref} active={active}>
      {children}
    </MainItem>
  )
}
export const MainFeature = () => {
  const userList = [
    {
      title: intl.get('solution8.solution.app.desc1'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh1 : ImgEn1,
    },
    {
      title: intl.get('solution8.solution.app.desc2'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh2 : ImgEn2,
    },
    {
      title: intl.get('solution8.solution.app.desc3'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh3 : ImgEn3,
    },
    {
      title: intl.get('solution8.solution.app.desc4'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh4 : ImgEn4,
    },
    {
      title: intl.get('solution8.solution.app.desc5'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh5 : ImgEn5,
    },
  ]
  const adminList = [
    {
      title: intl.get('solution8.solution.admin.desc1'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh6 : ImgEn6,
    },
    {
      title: intl.get('solution8.solution.admin.desc2'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh7 : ImgEn7,
    },
    {
      title: intl.get('solution8.solution.admin.desc3'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh8 : ImgEn8,
    },
    {
      title: intl.get('solution8.solution.admin.desc4'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh9 : ImgEn9,
    },
    {
      title: intl.get('solution8.solution.admin.desc5'),
      img: LocaleService.currentLocale === LOCALES.ZH ? ImgZh10 : ImgEn10,
    },
  ]
  const [userActived, setUserActived] = React.useState(0)
  const [adminActived, setAdminActived] = React.useState(0)

  const isMobile = useMedia()

  if (isMobile) {
    return (
      <>
        <H3 bottom={25} top={80}>
          {intl.get('solution8.solution.app.title')}
        </H3>
        <Swiper swiperNavType='touch'>
          {userList.map((l, i) => (
            <MobileMainItem key={i}>
              <img src={l.img} alt={l.title} />
              <H2>{l.title}</H2>
            </MobileMainItem>
          ))}
        </Swiper>
        <H3 bottom={25} top={80}>
          {intl.get('solution8.solution.admin.title')}
        </H3>
        <Swiper swiperNavType='touch'>
          {adminList.map((l, i) => (
            <MobileMainItem key={i}>
              <img src={l.img} alt={l.title} />
              <H2>{l.title}</H2>
            </MobileMainItem>
          ))}
        </Swiper>
      </>
    )
  }

  return (
    <Wrapper>
      <H3 bottom={25} top={80}>
        {intl.get('solution8.solution.app.title')}
      </H3>
      <FeatureContainer>
        <div>
          {userList.map((l, i) => (
            <FuncItem
              key={i}
              active={userActived === i}
              onTrigger={() => {
                setUserActived(i)
              }}
            >
              <H2>{l.title}</H2>
            </FuncItem>
          ))}
        </div>
        <img src={userList[userActived].img} alt='user-end' />
      </FeatureContainer>
      <H3 top={70} bottom={30} left={700}>
        {intl.get('solution8.solution.admin.title')}
      </H3>
      <FeatureContainer style={{ flexDirection: 'row-reverse' }}>
        <div>
          {adminList.map((l, i) => (
            <FuncItem
              key={i}
              active={adminActived === i}
              onTrigger={() => {
                setAdminActived(i)
              }}
            >
              <H2>{l.title}</H2>
            </FuncItem>
          ))}
        </div>
        <img src={adminList[adminActived].img} alt='admin-end' />
      </FeatureContainer>
    </Wrapper>
  )
}
class FileManagement extends React.PureComponent {
  render() {
    const { location } = this.props
    return (
      <Layout pageType='solution8' location={location}>
        <Swiper>
          <SolutionBanner type='solution8' pic={<Banner />} />
        </Swiper>
        <Section>
          <CommonTitle>{intl.get('solution8.challenge.title')}</CommonTitle>
          <SectionDesc top={25} bottom={18}>
            {intl.get('solution8.challenge.desc1')}
          </SectionDesc>
          <SectionDesc>{intl.get('solution8.challenge.desc2')}</SectionDesc>
          <SectionDesc>{intl.get('solution8.challenge.desc3')}</SectionDesc>
          <SectionDesc bottom={48}>{intl.get('solution8.challenge.desc4')}</SectionDesc>
          <SolutionDivider />
        </Section>
        <FeatureSection>
          <CommonTitle>{intl.get('solution8.solution.title')}</CommonTitle>
          <SectionDesc top={28}>{intl.get('solution8.solution.desc')}</SectionDesc>
          <MainFeature />
        </FeatureSection>

        <Swiper>
          <InviteBanner type='solution8' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default FileManagement
